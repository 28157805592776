export const validDomains = [
  'mail.ru',
  'yandex.ru',
  'bk.ru',
  'inbox.ru',
  'list.ru',
  'internet.ru',
  'vk.com',
  'gmail.com',
  'rambler.ru',
  'autorambler.ru',
  'myrambler.ru',
  'ro.ru',
  'icloud.com',
  'octoblu.org',
];