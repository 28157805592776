import React from "react";
import pl from "./Preloader.module.css";

function Preloader({ text }) {
  return (
    <div className={`${pl.preloader}`}>
      <div className={`${pl.skCircle}`}>
        <div className={`${pl.skCircle1} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle2} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle3} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle4} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle5} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle6} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle7} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle8} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle9} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle10} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle11} ${pl.skChild}`}></div>
        <div className={`${pl.skCircle12} ${pl.skChild}`}></div>
      </div>
    </div>
  );
}

export default Preloader;
