import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loyalty from "../screens/Loyalty";
import NotFound from "../screens/NotFound/NotFound"

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hasManySlashes = location.pathname.endsWith("/");
    const hasMultipleSlashes = /\/{2,}/.test(location.pathname);

    if (hasMultipleSlashes) {
      const newPathname = location.pathname.replace(/\/{2,}/g, "/");
      const newUrl = newPathname + location.search;
      navigate(newUrl, { replace: true });
    } else if (hasManySlashes && location.pathname !== "/") {
      const newPathname = location.pathname.replace(/\/$/, "");
      const newUrl = newPathname + location.search;
      navigate(newUrl, { replace: true });
    }
  }, [navigate, location]);

  return (
    <Routes>
      <Route element={<Loyalty />} path="/" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Router;