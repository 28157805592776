import { validDomains } from '../lib/lib'

export const checkIsValidEmail = (email) => {
  const emailRegex = new RegExp(`^[\\w\\-\\.!#$%&'*+/=?^_{}|~\\(\\)\\№]+@(${validDomains.join('|')})$`);
  const isValidEmail = emailRegex.test(email);

  return { isValidEmail }
}

export const reverseBirthday = (birthday) => {
  const [day, month, year] = birthday.split('.');
  return `${year}-${month}-${day}`;
};

export const checkIsValidBirthday = (value) => {
  if (!value) return true;
  const [day, month, year] = value.split('.').map(Number);

  const isValidDate =
    day >= 1 && day <= 31 && month >= 1 && month <= 12 && year >= 1940 && year <= new Date().getFullYear();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  if (year > currentYear) {
    return false;
  } else if (year === currentYear && month > currentMonth) {
    return false;
  } else if (year === currentYear && month === currentMonth && day > currentDay) {
    return false;
  }

  return { isValidDate };
} 