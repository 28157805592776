import React from "react";
import logo from "../images/logo.svg";

function LoyaltyBg() {
  return (
    <div className="loyalty__wrapper">
      <div className="loyalty__image"></div>

      <div className="loyalty__color">
        <div className="loyalty__text">
          <p>/ Специальные цены по&nbsp;карте лояльности</p>
          <p>/ Раннее информирование об&nbsp;акциях</p>
          <p>/ Возврат товара 365 дней</p>
        </div>
        <a href='https://desport.ru/' className="loyalty__logo">
          <img src={logo} alt="DESPORT" />
        </a>
      </div>
    </div>
  );
}

export default LoyaltyBg;
