import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ReactInputMask from 'react-input-mask';
import btn from '../Button/Button.module.css';
import cl from '../Input/Input.module.css';
import Preloader from '../Preloader/Preloader';
import { checkIsValidEmail, reverseBirthday, checkIsValidBirthday } from '../../shared/utils/utils';

function Form({ setIsRegistrationSuccess, repeatRequest, setIsRequestError, isRequestError }) {
  const [isCodeCheck, setIsCodeCheck] = useState(false);
  const [iscodeCheckError, setIsCodeCheckError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [counter, setCounter] = useState(0);
  const [restartTimer, setRestartTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  useEffect(
    (e) => {
      if (repeatRequest > 0) {
        if (!isCodeCheck) {
          console.log(e);
          sendFormData(e);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [repeatRequest]
  );

  const nav = useNavigate();

  const [formData, setFormData] = useState({
    gender: 'female',
    name: '',
    email: '',
    birthday: '',
    phone: '',
    checkboxOne: 'true',
    checkboxTwo: '',
    mindboxId: '',
  });
  const [formErrors, setFormErrors] = useState({
    genderError: false,
    nameError: false,
    emailError: false,
    birthdayError: false,
    phoneError: false,
    checkboxOneError: false,
  });

  const [codeFormData, setCodeFormData] = useState({ code: '' });
  const [codeFormError, setCodeFormError] = useState({ codeError: '' });

  const [isFormValid, setIsFormValid] = useState(false);

  const checkNameInput = (e) => {
    setFormData({
      ...formData,
      name: e.target.value.replace(/[^а-яА-ЯёЁ\s-]/g, ''),
    });

    const pattern = /^[А-ЯЁа-яё\s-]+$/i;

    const target = e.target;

    if (target.value.length < 1) {
      setFormErrors({ ...formErrors, nameError: true });
    }

    if (pattern.test(target.value)) {
      setFormErrors({ ...formErrors, nameError: false });

      if (e.type === 'blur') {
        target.closest('div').classList.add('done');
      }
    } else {
      setFormErrors({ ...formErrors, nameError: true });

      if (e.type === 'blur') {
        target.closest('div').classList.remove('done');
      }
    }
  };

  const checkEmailInput = (e) => {
    setFormData({
      ...formData,
      email: e.target.value.replace(/[А-аЯ-яЁё ]/, ''),
    });

    const { isValidEmail } = checkIsValidEmail(e.target.value);

    if (e.target.value.length < 1) {
      setFormErrors({ ...formErrors, emailError: true });
    } else if (e.target.value === '000') {
      setFormErrors({ ...formErrors, emailError: false });

      if (e.type === 'blur') {
        e.target.closest('div').classList.add('done');
      }
    } else {
      if (isValidEmail) {
        setFormErrors({ ...formErrors, emailError: false });

        if (e.type === 'blur') {
          e.target.closest('div').classList.add('done');
        }
      } else {
        setFormErrors({ ...formErrors, emailError: true });

        if (e.type === 'blur') {
          e.target.closest('div').classList.remove('done');
        }
      }
    }
  };

  const checkBirthDayInput = (e) => {
    setFormData({
      ...formData,
      birthday: e.target.value.replace(/[^0-9.]/g, ''),
    });

    const { isValidDate } = checkIsValidBirthday(formData.birthday);

    if (e.target.value.length < 1) {
      setFormErrors({ ...formErrors, birthdayError: false });
    } else if (e.target.value === '000') {
      setFormErrors({ ...formErrors, birthdayError: false });

      if (e.type === 'blur') {
        e.target.closest('div').classList.add('done');
      }
    } else {
      if (isValidDate) {
        setFormErrors({ ...formErrors, birthdayError: false });

        if (e.type === 'blur') {
          e.target.closest('div').classList.add('done');
        }
      } else {
        setFormErrors({ ...formErrors, birthdayError: true });

        if (e.type === 'blur') {
          e.target.closest('div').classList.remove('done');
        }
      }
    }
  };

  const formattedBirthday = reverseBirthday(formData.birthday);

  const checkPhoneInput = (e) => {
    setFormData({
      ...formData,
      phone: e.target.value,
    });

    const target = e.target;

    if (target.value.replace(/\s|_|\+|-|\(|\)/g, '').length < 11) {
      setFormErrors({ ...formErrors, phoneError: true });

      if (e.type === 'blur') {
        target.closest('div').classList.remove('done');
      }
    } else {
      setFormErrors({ ...formErrors, phoneError: false });

      if (e.type === 'blur') {
        target.closest('div').classList.add('done');
      }
    }
  };

  const checkCodeInput = (e) => {
    setCodeFormData({
      ...codeFormData,
      code: e.target.value,
    });

    const target = e.target;

    if (target.value.length < 4) {
      setCodeFormError({ ...codeFormError, codeError: true });

      if (e.type === 'blur') {
        target.closest('div').classList.remove('done');
      }
    } else {
      setCodeFormError({ ...codeFormError, codeError: false });

      if (e.type === 'blur') {
        target.closest('div').classList.add('done');
      }

      if (target.value.length === 4) {
        sendFormCode(target.value);
      }
    }

    setIsCodeCheckError(false);
  };

  let timer = useRef(null);

  useEffect(() => {
    if (counter > 0) {
      timer.current = setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    if (restartTimer > 1) {
      setCounter(60);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTimer]);

  useEffect(() => {
    setRestartTimer((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeCheck]);

  const checkIsRegistered = () => {
    if (isRegistered) {
      nav(0);
    }
  };

  useEffect(() => {
    let formCheck = false;
    let formErrorsCheck = false;

    if (
      formData.gender.length &&
      formData.name.length &&
      formData.email.length &&
      formData.phone.length &&
      formData.checkboxOne.length
    ) {
      formCheck = true;
    }

    if (
      !formErrors.genderError &&
      !formErrors.nameError &&
      !formErrors.emailError &&
      !formErrors.phoneError &&
      !formErrors.checkboxOneError &&
      !formErrors.birthdayError
      //   !formErrors.checkboxTwoError
    ) {
      formErrorsCheck = true;
    }

    if (formCheck && formErrorsCheck) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, formErrors]);

  const getToken = () => {
    try {
      return axios.get('/api/token/');
    } catch (error) {
      console.log(error);
      setIsRequestError(true);
    }
  };

  const sendFormData = () => {
    setIsLoading(true);
    const recapthca_token = process.env.REACT_APP_RECAPTCHA_KEY;
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(recapthca_token, {
          action: 'submit',
        })
        .then(function (token) {
          let requestData = new FormData();
          requestData.append('gender', formData.gender);
          requestData.append('name', formData.name);
          requestData.append('email', formData.email);
          requestData.append('birthday', formattedBirthday ?? '');
          requestData.append('phone', formData.phone.replace(/\s| |_|\+|-|\(|\)/g, ''));
          requestData.append('g-recaptcha-response', token);
          requestData.append('mindboxId', formData.mindboxId);

          getToken()
            .then((response) => response.data)
            .then((response) => {
              const token = response.token;
              // eslint-disable-next-line
              const sum = new Function(response.expression)();

              axios
                .post('/api/reg/', requestData, {
                  headers: { 'csrf-token': token, checkSum: sum },
                })
                .then((response) => response.data)
                .then(
                  (response) => {
                    if (
                      response?.customer?.processingStatus === 'Created' ||
                      response?.customer?.processingStatus === 'Found'
                    ) {
                      setFormData({ ...formData, mindboxId: response?.customer?.ids?.mindboxId });
                      setIsCodeCheck(true);
                    } else {
                      setIsRequestError(true);
                    }
                    setIsLoading(false);
                  },
                  (error) => {
                    error.response.data.message.includes('/customer/')
                      ? setIsRegistered(true)
                      : setIsRequestError(true);

                    setIsLoading(false);
                  }
                );
            });
        });
    });
  };

  const getNewCode = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const recapthca_token = process.env.REACT_APP_RECAPTCHA_KEY;
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(recapthca_token, {
          action: 'submit',
        })
        .then(function (token) {
          let requestData = new FormData();
          requestData.append('phone', formData.phone.replace(/\s| |_|\+|-|\(|\)/g, ''));
          requestData.append('g-recaptcha-response', token);
          requestData.append('mindboxId', formData.mindboxId);

          getToken()
            .then((response) => response.data)
            .then((response) => {
              const token = response.token;
              // eslint-disable-next-line
              const sum = new Function(response.expression)();

              axios
                .post('/api/resend/', requestData, {
                  headers: { 'csrf-token': token, checkSum: sum },
                })
                .then((response) => response.data)
                .then(
                  (response) => {
                    if (response?.customer?.processingStatus === 'Found') {
                      setRestartTimer((prev) => prev + 1);
                    } else {
                      setIsRequestError(true);
                    }

                    setIsLoading(false);
                  },
                  (error) => {
                    console.log(error);
                    setIsRequestError(true);

                    setIsLoading(false);
                  }
                );
            });
        });
    });
  };

  const sendFormCode = (code) => {
    setIsLoading(true);

    let requestData = new FormData();
    requestData.append('phone', formData.phone.replace(/\s| |_|\+|-|\(|\)/g, ''));
    requestData.append('code', code);
    requestData.append('mindboxId', formData.mindboxId);

    getToken()
      .then((response) => response.data)
      .then((response) => {
        const token = response.token;
        // eslint-disable-next-line
        const sum = new Function(response.expression)();

        axios
          .post('/api/check/', requestData, {
            headers: { 'csrf-token': token, checkSum: sum },
          })
          .then((response) => response.data)
          .then(
            (response) => {
              if (response?.smsConfirmation?.processingStatus !== 'IncorrectConfirmationCode') {
                setIsRegistrationSuccess(true);
              } else {
                setIsCodeCheckError(true);
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
              setIsRequestError(true);

              setIsLoading(false);
            }
          );
      });
  };

  return (
    <section data-nosnippet>
      {isLoading ? (
        <>
          <Preloader />
        </>
      ) : (
        <></>
      )}
      <form
        id="loyalty-form"
        className={`${'loyalty-form__form'} ${isRequestError ? 'hidden' : ''}`}
        autoComplete="off"
        onFocus={checkIsRegistered}
        onChange={isRegistered ? checkIsRegistered : null}
      >
        <div className="loyalty-form__gender">
          <div className="loyalty-form__gender-item">
            <label htmlFor="radioFemale" className={cl.radioBtn}>
              <input
                id="radioFemale"
                type="radio"
                name="gender"
                value="female"
                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                disabled={isCodeCheck ? true : false}
                checked={formData.gender === 'female' ? true : false}
              />
              <span />
              Женщина
            </label>
          </div>
          <div className="loyalty-form__gender-item">
            <label htmlFor="radioMale" className={cl.radioBtn}>
              <input
                id="radioMale"
                type="radio"
                name="gender"
                value="male"
                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                disabled={isCodeCheck ? true : false}
                checked={formData.gender === 'male' ? true : false}
              />
              <span />
              Мужчина
            </label>
          </div>
        </div>
        <div className={`${cl.inputWrapper} ${formErrors.nameError ? cl.inputWrapperError : ''}`}>
          <label>
            <p>
              Имя
              <span>*</span>
            </p>
            <input
              disabled={isCodeCheck ? true : false}
              placeholder={formErrors.nameError ? 'Обязательно для заполнения' : ''}
              value={formData.name}
              onChange={checkNameInput}
              onBlur={checkNameInput}
              maxLength="50"
            />
          </label>
        </div>
        <div className={`${cl.inputWrapper} ${formErrors.emailError ? cl.inputWrapperError : ''}`}>
          <label>
            <p>
              Email
              <span>*</span>
            </p>
            <input
              disabled={isCodeCheck ? true : false}
              placeholder={formErrors.emailError ? 'Обязательно для заполнения' : ''}
              value={formData.email}
              onChange={checkEmailInput}
              onBlur={checkEmailInput}
              maxLength="50"
            />
          </label>
        </div>
        {formErrors.emailError && formData.email.includes('@') && (
          <div className={cl.inputWrapperBirthday}>
            <p>
              Укажите используемую почту в&nbsp;существующих доменах. <br />
              Это важно для онлайн-заказов и&nbsp;получения чеков об&nbsp;онлайн-оплате.
            </p>
          </div>
        )}
        <div className={`${cl.inputWrapper} ${formErrors.birthdayError ? cl.inputWrapperError : ''}`}>
          <label>
            <p>{isMobile ? 'Дата рождения' : 'Дата рождения (дд.мм.гггг)'}</p>
            <ReactInputMask
              disabled={isCodeCheck ? true : false}
              value={formData.birthday}
              onChange={checkBirthDayInput}
              onBlur={checkBirthDayInput}
              mask={'99.99.9999'}
            />
          </label>
        </div>
        {!isCodeCheck ? (
          <>
            <div className={`${cl.inputWrapper} ${formErrors.phoneError ? cl.inputWrapperError : ''}`}>
              <label>
                <p>
                  Телефон
                  <span>*</span>
                </p>
                <ReactInputMask
                  mask="+7\ 999 999 99 99"
                  disabled={isCodeCheck ? true : false}
                  placeholder={formErrors.phoneError ? 'Обязательно для заполнения' : ''}
                  value={formData.phone}
                  onChange={checkPhoneInput}
                  onBlur={checkPhoneInput}
                />
              </label>
            </div>
            <button
              type="button"
              className={`${btn.button} ${isRegistered ? 'hidden' : ''}`}
              disabled={!isFormValid}
              onClick={(e) => sendFormData(e)}
            >
              Вступить в клуб
            </button>
          </>
        ) : (
          <>
            <div className={cl.codeInfo}>
              Мы отправили код подтверждения на номер <span>{formData.phone}</span>.&nbsp;
              <p onClick={() => setIsCodeCheck(false)}>Изменить</p>
            </div>
            <div className={cl.inputField}>
              <div
                className={`${cl.inputWrapper} ${cl.inputWrapperCode} ${
                  codeFormError.codeError ? cl.inputWrapperError : ''
                }`}
              >
                <label>
                  <p>Введите код (СМС):</p>
                  <input
                    maxLength="4"
                    autoFocus
                    value={codeFormData.code}
                    onChange={checkCodeInput}
                    onBlur={checkCodeInput}
                  />
                </label>
              </div>
              {iscodeCheckError ? (
                <>
                  <p class={cl.codeCheckError}>Код введен неверно. Проверьте код и пробуйте еще раз.</p>
                </>
              ) : (
                <></>
              )}
              <div
                className={`${cl.newCodeBtn} ${!counter ? cl.newCodeBtnActive : ''}`}
                onClick={(e) => (!counter ? getNewCode(e) : null)}
              >
                Отправить новый&nbsp;
                <span>{counter ? `(${counter})` : ''}</span>
              </div>
            </div>
          </>
        )}
        <div className={`${cl.checkboxWrapper} ${isRegistered ? 'hidden' : ''}`}>
          <label htmlFor="formCheckboxOne">
            <input
              id="formCheckboxOne"
              type="checkbox"
              disabled={isCodeCheck ? true : false}
              value="true"
              onClick={(e) =>
                setFormData({
                  ...formData,
                  checkboxOne: e.target.value,
                })
              }
              checked={formData.checkboxOne}
            />
            <span></span>
            Я&nbsp;даю согласие на&nbsp;обработку персональных данных в&nbsp;соответствии с&nbsp;
            <a target="blank" href="/data-processing-policy.pdf">
              Политикой обработки персональных данных
            </a>
            , принимаю{' '}
            <a target="blank" href="/Общие условия продаж.pdf">
              Оферту
            </a>{' '}
            и&nbsp;
            <a target="blank" href="/politica-consent.pdf">
              Пользовательское соглашение
            </a>{' '}
            о&nbsp;конфиденциальности с&nbsp;АО &laquo;Октоблу&raquo; . Я&nbsp;подтверждаю, что все указанные
            в&nbsp;настоящей анкете данные верны, а&nbsp;также признаю ответственность за&nbsp;предоставление
            недостоверной информации.
          </label>
        </div>

        <div className={`${cl.checkboxWrapper} ${isRegistered ? 'hidden' : ''}`}>
          <label htmlFor="formCheckboxTwo">
            <input
              id="formCheckboxTwo"
              type="checkbox"
              disabled={isCodeCheck ? true : false}
              value="false"
              onClick={(e) =>
                setFormData({
                  ...formData,
                  checkboxTwo: e.target.checked ? e.target.value : '',
                })
              }
              checked={formData.checkboxTwo ? true : false}
            />
            <span></span>Я даю согласие на получение коммуникаций рекламно–информационного характера, включая, но не
            ограничиваясь: СМС-рассылки, e-mail-рассылки, телефония, сеть Интернет и мобильные устройства.
          </label>
        </div>
        {isRegistered ? (
          <div className="registration-error">
            <p>Вы уже зарегистрированы в программе лояльности.</p>
            <p>Сообщите на кассе ваш номер телефона.</p>
          </div>
        ) : null}
      </form>
    </section>
  );
}

export default Form;
