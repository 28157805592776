import React, { useState } from "react";
import LoyaltyBg from "../components/LoyaltyBg";
import Form from "../components/Form/Form";
import logo from "../images/logo.svg";

function Loyalty() {
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const [repeatRequest, setRepeatRequest] = useState(0);

  const updateRepeatRequest = () => {
    setRepeatRequest((prev) => prev + 1);
    setIsRequestError(false);
  };

  return (
    <div className="loyalty">
      {!isRegistrationSuccess || !isRequestError ? (
        <>
          <LoyaltyBg />
        </>
      ) : (
        <></>
      )}
      <div className="loyalty-form">
        {isRequestError ? (
          <>
            <div className="error-block">
              <div className="error-block__logo">
                <a href="https://desport.ru/">
                  <img src={logo} alt="DESPORT" />
                </a>
              </div>
              <div className="error-block__icon">
                <span></span>
              </div>
              <div className="error-block__title">
                <span>/ сервер</span> не отвечает
              </div>
              <span
                className="error-block__button"
                onClick={updateRepeatRequest}
              >
                Обновить запрос
              </span>
            </div>
          </>
        ) : (
          ""
        )}
        {isRegistrationSuccess ? (
          <>
            <div className="success-block">
              <div className="success-block__logo">
                <a href="https://desport.ru/">
                  <img src={logo} alt="DESPORT" />
                </a>
              </div>
              <div className="success-block__icon">
                <span></span>
              </div>
              <div className="success-block__title">
                <span>/ поздравляем</span> вы стали Участником DEclub
              </div>
              <div className="success-block__text">
                Сообщите на кассе ваш номер телефона.
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="loyalty-form__title">
              <span>/ Анкета</span> Участника Declub
            </h1>
            <Form
              setIsRegistrationSuccess={setIsRegistrationSuccess}
              repeatRequest={repeatRequest}
              isRequestError={isRequestError}
              setIsRequestError={setIsRequestError}
            />
          </>
        )}
      </div>
      <div className="pagePadding"></div>
    </div>
  );
}

export default Loyalty;
