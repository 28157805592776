import React from 'react';
import './notFound.scss';
import { useEffect, useState, useRef, useMemo } from 'react';

const NotFound = () => {
  const [scroll, setScroll] = useState(false);
  const imageRef = useRef(null);
  const infoTextRef = useRef(null);

  const mobileWidth = useMemo(() => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth < 426;
      return width;
    }
    return false;
  }, []);

  const handleScroll = () => {
    setTimeout(() => {
      setScroll(true);
    }, 200);
  };

  useEffect(() => {
    if (scroll && imageRef.current && mobileWidth) {
      imageRef.current.classList.add('scroll');
      infoTextRef.current?.classList.add('scroll-text');
    }
  }, [scroll, mobileWidth]);

  return (
    <div className="not-found" onTouchStart={handleScroll}>
      <div className="not-found__up">
        <a href={'/'} className="not-found__back up">
          Вернуться на&nbsp;главную
        </a>
        <img className='logo' src="icons/Desport.svg" alt="logo" />
      </div>
      <div className="not-found__down">
        <div className="not-found__left" ref={infoTextRef}>
          <p className="not-found__left__title">
            <span className="not-found__left__title__sub">/ 404</span> <br />
            страница не&nbsp;найдена
          </p>
          <p className="not-found__left__subtitle">
            К&nbsp;сожалению, запрашиваемая вами страница была перемещена или удалена, а возможно,
            её никогда не&nbsp;было.
          </p>
          <a href={'/'} className="not-found__back down">
            Вернуться на&nbsp;главную
          </a>
        </div>
        <div className={'not-found__right'} ref={imageRef}>
          <img
            src={'icons/404.svg'}
            alt="404"
            className="not-found__right__img"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
